import {UPDATE_RESOLUTION} from "../actionTypes";
import {GET_FUNDRAISING_DATES, GET_MINI_FAQ, GET_PARTICIPATING_FOUNDERS, SET_FUNDRAISING_STATS} from "../actions/types";
import {METADATA} from "../../helpers/metadata";

const initialState = {
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  innerWidth: window.innerWidth,
  miniFaqs: null,

  fundraisingDates: { ...METADATA.default, data: null },
  participatingFounders: { ...METADATA.default, data: null },
  fundraisingStats: null,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_RESOLUTION:
      return {...state, ...action.payload};
    case GET_MINI_FAQ.SUCCESS:
      return { ...state, miniFaqs: action.payload };
    case GET_FUNDRAISING_DATES.REQUEST:
      return { ...state, fundraisingDates: { ...state.fundraisingDates, ...METADATA.request }};
    case GET_FUNDRAISING_DATES.SUCCESS:
      return { ...state, fundraisingDates: { ...METADATA.success, data: action.payload }};
    case GET_FUNDRAISING_DATES.FAILURE:
      return { ...state, fundraisingDates: { ...state.fundraisingDates, ...METADATA.error, error: action.error, data: null }};
    case SET_FUNDRAISING_STATS:
      return { ...state, fundraisingStats: action.payload };
    case GET_PARTICIPATING_FOUNDERS.REQUEST:
      return { ...state, participatingFounders: { ...state.participatingFounders, ...METADATA.request }};
    case GET_PARTICIPATING_FOUNDERS.SUCCESS:
      return { ...state, participatingFounders: { ...METADATA.success, data: action.payload }};
    case GET_PARTICIPATING_FOUNDERS.FAILURE:
      return { ...state, participatingFounders: { ...state.participatingFounders, ...METADATA.error, error: action.error, data: null }};
    default:
      return state;
  }
};

export default appReducer;