import React, {useState} from 'react';
import moment from "moment";
import {secondsToDhms} from "../../helpers/utils";

let intervalID = null;

const Countdown = ({endDate, onFinish, className}) => {
  const [text, setText] = useState();

  React.useEffect(() => {
    initCounter(endDate);
    return  () => {
      clearInterval(intervalID);
    }
  }, [endDate]);

  const initCounter = (endDate) => {
    clearInterval(intervalID);

    const date = moment(endDate, 'YYYY-MM-DD');

    intervalID = setInterval(function() {
      const now = moment();
      const timeLeft = secondsToDhms(date.diff(now, 'seconds'));

      if (timeLeft) {
        setText(`Time left ${timeLeft}`)
      } else {
        onFinish();
        clearInterval(intervalID)
      }
    }, 1000);
  }

  return (
    <div className={className}>{text}</div>
  );
};

export default Countdown;