import React from 'react';
import * as classnames from 'classnames';
import parseUrl from 'parse-url';
import {MyLink} from '../MyLink';

const Linker = ({className, value}) => {
  let domain = '';
  const params = {}
  const url = parseUrl(value);
  domain = url.resource;
  params.href = url.href;

  return (
    <MyLink {...params} className={classnames("linker", "tl", className)} isExternal>
      {domain || value}
    </MyLink>
  );
};

export default Linker;