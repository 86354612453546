import React from "react"
import DesktopFooter from "./DesktopFooter/DesktopFooter"
import MobFooter from "./MobFooter/MobFooter"
import {useSelector} from "react-redux";

const Footer = props => {
  const {isTablet} = useSelector(state => state.appStore);
  const date = new Date()
  const currentYear = date.getFullYear()

  const onClickFooterNavItems = () => {
    window.scrollTo(0, 0)
  }

  const mobile = props.mobile || isTablet;

  return (
    <>
      {mobile ? (
        <MobFooter
          currentYear={currentYear}
          onClickFooterNavItems={onClickFooterNavItems}
        />
      ) : (
        <DesktopFooter
          currentYear={currentYear}
          onClickFooterNavItems={onClickFooterNavItems}
        />
      )}
    </>
  )
}

export default Footer
