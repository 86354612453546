import axios from "../../axios-api";
import {UPDATE_RESOLUTION} from "../actionTypes";
import Pathes from "../../helpers/pathes";
import {GET_FUNDRAISING_DATES, GET_MINI_FAQ, GET_PARTICIPATING_FOUNDERS, SET_FUNDRAISING_STATS} from "./types";

const getMiniFaqSuccess = payload => ({type: GET_MINI_FAQ.SUCCESS, payload});

const getFundraisingDatesRequest = () => ({ type: GET_FUNDRAISING_DATES.REQUEST });
const getFundraisingDatesSuccess = payload => ({type: GET_FUNDRAISING_DATES.SUCCESS, payload});
const getFundraisingDatesFailure = error => ({type: GET_FUNDRAISING_DATES.FAILURE, error});

const getParticipatingFoundersRequest = () => ({ type: GET_PARTICIPATING_FOUNDERS.REQUEST });
const getParticipatingFoundersSuccess = payload => ({type: GET_PARTICIPATING_FOUNDERS.SUCCESS, payload});
const getParticipatingFoundersFailure = error => ({type: GET_PARTICIPATING_FOUNDERS.FAILURE, error});

const setFundraisingStats = payload => ({type: SET_FUNDRAISING_STATS, payload});

export const updateResolution = width => {
  return dispatch => {
    const payload = {
      isMobile: width <= 414,
      isTablet: width > 414 && width <= 1024,
      isDesktop: width > 1024,
      innerWidth: width,
    };
    dispatch({type: UPDATE_RESOLUTION, payload});
  }
}

export const getMiniFaq = source => {
  return dispatch => {
    return axios.get(Pathes.Help.section(source)).then(
      response => {
        const {status, data} = response;
        if (status === 200) {
          dispatch(getMiniFaqSuccess(data));
          return {data, success: true};
        }
        throw new Error('Error')
      }).catch(e => ({error: e.message, success: false}));
  };
}

export const getFundraisingDates = () => {
  return dispatch => {
    dispatch(getFundraisingDatesRequest());
    return axios.get(Pathes.Fundraising.dates).then(
      response => {
        const {status, data} = response;
        if (status === 200) {
          dispatch(getFundraisingDatesSuccess(data));
          return {data, success: true};
        }
        throw new Error('Error')
      }).catch(e => {
        dispatch(getFundraisingDatesFailure(e.message));
        return {error: e.message, success: false}
    });
  };
}

export const getFundraisingStats = () => {
  return dispatch => {
    return axios.get(Pathes.Fundraising.stats).then(
      response => {
        const {status, data} = response;
        if (status === 200) {
          dispatch(setFundraisingStats(data));
          return {data, success: true};
        }
        throw new Error('Error')
      }).catch(e => ({error: e.message, success: false}));
  };
}

export const getParticipatingFounders = () => {
  return dispatch => {
    dispatch(getParticipatingFoundersRequest());
    return axios.get(Pathes.Fundraising.founders).then(
      response => {
        const {status, data} = response;
        if (status === 200) {
          dispatch(getParticipatingFoundersSuccess(data));
          return {data, success: true};
        }
        throw new Error('Error')
      }).catch(e => {
      dispatch(getParticipatingFoundersFailure(e.message));
      return {error: e.message, success: false}
    });
  };
}