import config from "../config";

export const CONTACT_EMAIL = 'ask@scrambleup.com';

export const LINKS = {
  main: config.investorPromoDomain,
  forInvestors: config.investorPromoDomain,
  forFounders: config.founderPromoDomain,
  product: `${config.investorPromoDomain}/product`,
  howItWorks: `${config.investorPromoDomain}/how-it-works`,
  about: `${config.investorPromoDomain}/about`,
  contactUs: `${config.investorPromoDomain}/contacts`,
  help: `${config.investorPromoDomain}/help`,
  termsOfUse: `${config.investorPromoDomain}/docs/Terms_of_use.pdf`,
  privacyAndPolicy: `${config.investorPromoDomain}/docs/Privacy_policy.pdf`,
  signIn: `${config.investorApp}/sign-in`,
  signUp: `${config.investorApp}/sign-up`,
  getStarted: `${config.investorApp}/sign-up`,
  blog: config.blogDomain,
}