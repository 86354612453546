import React from 'react';
import * as classnames from 'classnames';
import './index.scss';

const SimpleStepper = ({ stepNumber = 1, vertical, className }) => (
  <div className={classnames('simple_stepper__wrap', className)}>
    <ul className={classnames('simple_stepper', vertical && 'vertical')}>
      {new Array(stepNumber).fill('').map((_, index) => {
        const step = index + 1;

        return (
          <li
            key={index}
            className={classnames('simple_stepper__step', 'f-26-32 f-500')}
          >
            {step <= 9 ? `0${step}` : step}
          </li>
        );
      })}
    </ul>
  </div>
);

export default SimpleStepper;
