import React, {useState} from 'react';
import * as classnames from "classnames";
import './earn.scss';
import './index.scss';

const STEPS = [
  {
    count: '1 friend',
    amount: '€25'
  },
  {
    count: '5 friends',
    amount: '€125'
  },
  {
    count: '10 friends',
    amount: '€250'
  },
  {
    count: '20 friends',
    amount: '€500'
  },
  {
    count: '30+ friends',
    amount: '€750+'
  }
];

const EarnBlock = ({vertical, className}) => {
  const [hoverIndex, setHoverIndex] = useState('');

  const onMouseOver = (index) => {
   setHoverIndex(index);
  }

  const onMouseLeave = () => {
    setHoverIndex('');
  }

  return (
    <div className="earn_block">
      <div className="earn_block__title f-18 f-400">Your earnings</div>
      <div className="earn_block__container">
        <div className="earn_block__subtitle">You refer</div>
        <div className={classnames('earn_stepper__wrap', className)}>
          <ul className={classnames('earn_stepper', vertical && 'vertical')}>
            {STEPS.map((item, index) => (
              <li key={item.amount} data-label={index} className={classnames('earn_stepper__step', 'f-26-32 f-500', hoverIndex === index + 1 && 'active')}>
                <span className="earn_stepper__amount" onMouseOver={() => onMouseOver(index + 1)} onMouseLeave={onMouseLeave}>{item.amount}</span>
                <span className="earn_stepper__friends" onMouseOver={() => onMouseOver(index + 1)} onMouseLeave={onMouseLeave}>{item.count}</span>
              </li>
            ))}
          </ul>
        </div>

      </div>
    </div>
  );
};

export default EarnBlock;