import React from 'react';
import {useSelector} from 'react-redux';
import PageHelmet from '../../components/PageHelmet';
import config from '../../config';
import {LINKS} from '../../helpers/constants';
import {MyLink} from '../../components/MyLink';
import SimpleStepper from '../../components/SimpleStepper';
import MiniFaqs, {MINIFAQ_SOURCES} from '../../containers/MiniFaqs';
import EarnBlock from './earn';
import './index.scss';

const SEO = {
  title: 'Affiliate program │ Scramble',
  description: "Here's your opportunity to invest in high-growth startups on Scramble. Be part of those amazing success stories during this round's batch. Start investing now!",
  image: null
}

const CONS = [
  {
    title: 'For you',
    content: () => (
      <ul className="f-18">
        <li><b>5%</b> for every deal</li>
        <li><b>No limit!</b><br /> <span className="nowrap">Refer more</span><br />and earn more</li>
      </ul>
    ),
  },
  {
    title: 'For your friend',
    content: () => (
      <ul className="f-18">
        <li><b>+ €5</b> starting bonus</li>
        <li><b>Protected</b><br /> way for investing</li>
      </ul>
    ),
  }
];


const AffiliateInfluencersPage = () => {
  const {innerWidth}  = useSelector(state => state.appStore);
  return (
    <>
      <PageHelmet
        title={SEO.title}
        description={SEO.description}
        image={SEO.image}
        url={config.baseURL + `/round`}
      />

      <div className="aff_influencers_page">
        <div className="aff_influencers_page__inner">
          <div className="container">
            <h1 className="aff_influencers_page__title f-42-48 f-600">
              Invite your friends and earn rewards
            </h1>
            <p className="aff_influencers_page__desc f-18 f-400">
              Help us spread the word about high-yield passive income investments. Whether you're an influencer, comparison site, or publisher, join our referral program and earn great rewards by promoting Scramble to your audience.
            </p>

            <MyLink
              href={LINKS.signUp}
              className="aff_influencers_page__link scr-button yellow f-18 f-500"
              isExternal
            >
              Sign up and start earning
            </MyLink>


            <section className="aff_influencers_page__how">
              <h2 className="aff_influencers_page__how_title f-26-32 f-500">
                How it works ?
              </h2>
              <p className="f-18 f-400">
                Our referral program is easy. Sign up to Scramble, and you’ll find unique tracking link and materials for creating a successful Scramble campaign.
              </p>
              <p className="f-18 f-400">
                Share your personal referral link with as many people as you like in any way you choose. Both you and whomever you refer will receive great cash rewards – that’s potential investment, absolutely free! The more people you invite through a referral link, the more you can earn!
              </p>

              <div className="aff_influencers_page__how_works">
                <div className="f-24-36 f-500">We offer a great deal for both!</div>
                <div className="aff_influencers_page__how_circles">
                  {CONS.map(item => (
                    <MyLink key={item.title} href={item.link} isExternal className="aff_influencers_page__how_circle">
                    <div className="aff_influencers_page__how_circle_content">
                      <div className="f-26-32 f-600">{item.title}</div>
                      {item.content()}
                    </div>
                    </MyLink>
                  ))}
                </div>
              </div>
            </section>

            <section className="aff_influencers_page__earn">
              <h2 className="aff_influencers_page__earn_title f-26-32 f-500">
                How much will I earn?
              </h2>
              <p className="aff_influencers_page__earn_desc f-18 f-400">
                An example. Let’s say all your referred friends invested €500 each:
              </p>

              <div className="aff_influencers_page__earn_pic">
                <EarnBlock />
              </div>

              <MyLink
                href={LINKS.signUp}
                className="aff_influencers_page__link scr-button yellow two f-18 f-500"
                isExternal
              >
                Sign up and start earning
              </MyLink>


              <div className="aff_influencers_page__start_earn">
                <div className="aff_influencers_page__start_earn_title f-18 f-400">
                  Start earning rewards right away by inviting your friends to Scramble.
                </div>

                <div className="aff_influencers_page__start_earn_inner">
                  <SimpleStepper
                    stepNumber={3}
                    vertical={innerWidth < 600}
                    className="aff_influencers_page__start_earn_stepper"
                  />
                  <div className="aff_influencers_page__start_earn_options">
                    <span>Create account</span>
                    <span>Start promoting</span>
                    <span>Earn your rewards</span>
                  </div>
                </div>

                <MyLink
                  href={LINKS.signUp}
                  className="aff_influencers_page__link scr-button yellow two f-18 f-500"
                  isExternal
                >
                  Sign up and start earning
                </MyLink>
              </div>
            </section>

            <MiniFaqs source={MINIFAQ_SOURCES.partner_landings}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default AffiliateInfluencersPage;