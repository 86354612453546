const ENVIRONMENT = process.env.REACT_APP_ENV || 'production';
const DEV_API_URL = process.env.REACT_APP_DEV_API_URL || 'http://localhost:8000';
const FACEBOOK_LINK = process.env.REACT_APP_FACEBOOK_LINK || '#';
const LINKEDIN_LINK = process.env.REACT_APP_LINKEDIN_LINK || '#';
const API_URL = process.env.REACT_APP_API_URL || '';
const INVESTOR_APP_DOMAIN = process.env.REACT_APP_INVESTOR_APP_DOMAIN || '';
const FOUNDER_APP_DOMAIN = process.env.REACT_APP_FOUNDER_APP_DOMAIN || '';
const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID || '';
const GOOGLE_APP_ID = process.env.REACT_APP_GOOGLE_APP_ID || '';
const LINKEDIN_APP_ID = process.env.REACT_APP_LINKEDIN_APP_ID || '';
const VERIFF_API_KEY = process.env.REACT_APP_VERIFF_PUBLISHABLE_KEY || '';
const INVESTOR_PROMO_DOMAIN = process.env.REACT_APP_INVESTOR_PROMO_DOMAIN || '';
const FOUNDER_PROMO_DOMAIN = process.env.REACT_APP_FOUNDER_PROMO_DOMAIN || '';
const BLOG_DOMAIN = process.env.REACT_APP_BLOG_DOMAIN || '';
const VERIFF_HOST_URL = 'https://stationapi.veriff.com';
const PREFIX = '/api/';

const getApiURL = () => (ENVIRONMENT === 'development' ? DEV_API_URL : API_URL);

const config = {
  investorPromoDomain: INVESTOR_PROMO_DOMAIN,
  founderPromoDomain: FOUNDER_PROMO_DOMAIN,
  parentDomain: INVESTOR_PROMO_DOMAIN,
  investorApp: INVESTOR_APP_DOMAIN,
  founderApp: FOUNDER_APP_DOMAIN,
  blogDomain: BLOG_DOMAIN,
  facebookLink: FACEBOOK_LINK,
  linkedinLink: LINKEDIN_LINK,
  baseURL: window.location.origin.toString(),
  apiURL: getApiURL() + PREFIX,
  facebookAppId: FACEBOOK_APP_ID,
  googleAppId: GOOGLE_APP_ID,
  linkedinId: LINKEDIN_APP_ID,
  veriffApiKey: VERIFF_API_KEY,
  veriffHostURL: VERIFF_HOST_URL,
  environment: ENVIRONMENT,
};

export default config;
