export default class Pathes {
  static Help = class {
    static section = source => `/mini_faqs/?page=${source}`;
  };

  static Fundraising = class {
     static dates = `/public/fundraising_dates/`;
     static stats = `/public/fundraising_stats/`;
     static founders = `/public/active_round_founders/?limit=10&page=1`;
  }
}
