import React from "react";
import PageHelmet from "../../components/PageHelmet";
import config from "../../config";
import withScroll from "../../hoc/withScroll";
import {MyLink} from "../../components/MyLink";
import {LINKS} from "../../helpers/constants";

import ContactForm from "../../components/ContactForm";
import Logo from '../../assets/images/logo_ptaha.png';
import PhoneImage from '../../assets/images/bg_image_phone.png';
import VideoImage from '../../assets/images/ptaha_image.png';

import './index.scss';

const SEO = {
  title: 'Scramble - Early Birdies',
  description: 'Регистрируйся на платформе и принимай участие в финансировании развития Ранних Птах. Пришло время к устойчивому росту компании, получи возможность стать частью этого развития!',
  image: PhoneImage
}

const CONS = [
  {
    title: 'Доходность выше\n среднего',
    desc: 'от 12% до 18%\n годовых\n в евро',
    link: 'https://scrambleup.com/product?step=3'
  },
  {
    title: 'Высокая\n степень защиты',
    desc: 'Трехступенчатая механика защиты\n от рисков',
    link: 'https://scrambleup.com/product?step=4'
  },
  {
    title: 'Тщательный\n отбор компаний',
    desc: 'Новая\n группа компаний каждый месяц',
    link: 'https://scrambleup.com/how-it-works?step=2'
  }
];

const EarlybirdiesPage = () => {
  return (
    <>
      <PageHelmet
        title={SEO.title}
        description={SEO.description}
        image={SEO.image}
        url={config.baseURL + `/earlybirdies`}
      />

      <div className="earlybirdies_page">
        <div className="earlybirdies_page__main">
          <div className="container">
            <div className="earlybirdies_page__inner">
              <div className="earlybirdies_page__content">
                <MyLink href="http://www.ptahi.com" isExternal className="earlybirdies_page__logo">
                  <img src={Logo} alt="App logo" width="221" height="75" />
                </MyLink>
                <h1 className="earlybirdies_page__title f-45-55 f-600">
                  Встречай ‘РАННИЕ&nbsp;ПТАХИ’ в январском раунде финансирования!
                </h1>
                <p className="earlybirdies_page__desc f-25-30 f-400">
                  Регистрируйся на платформе и принимай участие в финансировании развития Ранних Птах. Пришло время к устойчивому росту компании, получи возможность стать частью этого развития!
                </p>
                <MyLink href={LINKS.signUp} isExternal className="earlybirdies_page__investor_link scr-button yellow">
                  Стать инвестором
                </MyLink>
                <div className="earlybirdies_page__note">
                  Мы заботимся о том, чтобы риски инвестирования на платформе Scramble
                  были минимальны. Однако любые инвестиции всегда связаны с риском,
                  поэтому мы рекомендуем принимать взвешенные финансовые решения.
                </div>
                <div className="earlybirdies_page__main-image">
                  <img src={PhoneImage} alt="Phone" width="360" height="476" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="earlybirdies_page__scramble">
          <div className="container">
            <h2 className="earlybirdies_page__scramble-title f-45-55 f-600">
              Scramble - это удобное вложение <br /> в быстрорастущие инновационные компании
            </h2>
            <p className="earlybirdies_page__scramble-desc f-25-30 f-400">
              На платформе Scramble финансирование предоставляется инновационным бизнесам с проверенными бизнес-моделями и сильными операционными командами. Каждое вложение защищено личным поручительством основателей бизнесов.
            </p>
            <div className="earlybirdies_page__scramble-circles">
              {CONS.map(item => (
                <a href={item.link} className="earlybirdies_page__scramble-circle">
                  <div className="earlybirdies_page__scramble-circle-content">
                    <h3>{item.title}</h3>
                    <p>{item.desc}</p>
                    <span>Подробнее (ENG)</span>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </section>

        <section className="earlybirdies_page__works">
          <div className="container">
            <h2 className="earlybirdies_page__works-title f-45-55 f-600">
              Как это работает?
            </h2>
            <p className="earlybirdies_page__works-desc f-25-30 f-400">
              Демо видео на английском языке.
            </p>
            <a href="https://scrambleup.com/how-it-works?autoplay=1" className="earlybirdies_page__works-video">
              <img src={VideoImage} width="1110" height="624" alt="Video"/>
            </a>
          </div>
        </section>

        <section className="earlybirdies_page__contacts">
          <div className="container">
            <h2 className="earlybirdies_page__contacts-title f-45-55 f-600">
              Получить консультацию
            </h2>
            <ContactForm source="promo_contact_birdies" />
          </div>
        </section>

        <section className="earlybirdies_page__faqs">
          <div className="container">
            <h2 className="earlybirdies_page__faqs-title f-45-55 f-600">Часто задаваемые вопросы:</h2>

            <div className="earlybirdies_page__faqs-list">
              <div className="faq_question f-25-30 f-400">
                <div className="faq_question__title">Какой порог входа?</div>
                <div className="faq_question__answer">Клиенты Ранних Птах могут участвовать в финансировании на любую сумму. Даже 1 EUR. </div>
              </div>

              <div className="faq_question f-25-30 f-400">
                <div className="faq_question__title">Какие финансовые условия вы предлагаете?</div>
                <div className="faq_question__answer">Целевая доходность от 12% до 18% годовых в евро со сроком займа на 12 месяцев. Проценты по займу выплачиваются начиная с первого месяца, а тело займа с 7 по 12 месяц.</div>
              </div>

              <div className="faq_question f-25-30 f-400">
                <div className="faq_question__title">Какая вероятность потери вложений?</div>
                <div className="faq_question__answer">Ранние Птахи - это молодая компания, которая с точки зрения финансовых организаций относится к категории рискованных заемщиков. Платформа Scramble отвечает за отбор наиболее качественных заемщиков среди большого числа кандидатов и минимизацию возможных потерь инвесторов в случае наступления дефолтов по займам. Любые инвестиции подобного рода всегда связаны с риском потери денег вплоть до всей суммы вложений. Мы не рекомендуем вкладывать деньги в рискованные займы, если вы не готовы к возможным финансовым потерям.  Более подробную информацию о финансовых условиях, финансовых рисках и механизмах защиты инвесторов можно узнать на сайте scrambleup.com</div>
              </div>
            </div>

            <div className="earlybirdies_page__buttons">
              <a href="https://scrambleup.com" className="scr-button white">Узнать подробнее</a>
              <a href="https://investor.scrambleup.com/sign-up" className="scr-button yellow">Стать инвестором</a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default withScroll(EarlybirdiesPage);