import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleDropdown from '../../components/UI/SimpleDropdown';
import {getMiniFaq} from "../../store/actions/appAction";
import './index.scss';

export const MINIFAQ_SOURCES = {
  round: 'Round',
  partner_landings: 'Partner-landing',
  affiliate: 'affiliate',
  affiliate_invite_friends: 'affiliate-invite-friends',
};

const MiniFaqs = ({ source }) => {
  const dispatch = useDispatch();
  const pageQuestions = useSelector(state => state.appStore.miniFaqs);

  useEffect(() => {
    dispatch(getMiniFaq(source));
  }, []);

  if (!pageQuestions) {
    return null;
  }

  return (
    <div className="mini-faqs">
      <h4 className="mini-faqs__title f-26-32 f-500">FAQ</h4>
      <div className="mini-faqs__list">
        {pageQuestions.faqs.map(item => (
          <SimpleDropdown
            key={item.id}
            data={item}
            label={item.faq.question}
            innerContentHTML={item.faq.answer}
          />
        ))}
      </div>
    </div>
  );
};

export default MiniFaqs;
