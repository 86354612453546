export const GET_MINI_FAQ =  {
  REQUEST: 'GET_MINI_FAQ_REQUEST',
  SUCCESS: 'GET_MINI_FAQ_SUCCESS',
  FAILURE: 'GET_MINI_FAQ_FAILURE',
}

export const GET_FUNDRAISING_DATES =  {
  REQUEST: 'GET_FUNDRAISING_DATES_REQUEST',
  SUCCESS: 'GET_FUNDRAISING_DATES_SUCCESS',
  FAILURE: 'GET_FUNDRAISING_DATES_FAILURE',
}

export const GET_PARTICIPATING_FOUNDERS =  {
  REQUEST: 'GET_PARTICIPATING_FOUNDERS_REQUEST',
  SUCCESS: 'GET_PARTICIPATING_FOUNDERS_SUCCESS',
  FAILURE: 'GET_PARTICIPATING_FOUNDERS_FAILURE',
}

export const SET_FUNDRAISING_STATS =  'SET_FUNDRAISING_STATS';