import React from 'react';
import './index.scss';

const Page404 = () => (
  <div className="page_404">
    <div className="container">
      <div className="page_404__wrapper">
        <div className="page_404__content">
          404 - Страница не найдена<br />
        </div>
      </div>
    </div>
  </div>
);

export default Page404;