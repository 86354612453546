import React from "react"

import DesktopHeader from "./DesktopHeader/DesktopHeader"
import MobHeader from "./MobHeader/MobHeader"
import '../index.scss'

const Header = ({ mobile }) => {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false)

  const onClickMenuItems = () => {
    setMenuIsOpen(false)
    window.scrollTo(0, 0)
  }

  const handleMenuOpen = () => {
    setMenuIsOpen(!menuIsOpen)
  }

  return (
    <>
      {mobile ? (
        <>
          <div className={menuIsOpen ? "pseudo_header" : ""} />
          <MobHeader
            onClickMenuItems={onClickMenuItems}
            menuIsOpen={menuIsOpen}
            handleMenuOpen={handleMenuOpen}
          />
        </>
      ) : (
        <DesktopHeader
          onClickMenuItems={onClickMenuItems}
          menuIsOpen={menuIsOpen}
          handleMenuOpen={handleMenuOpen}
        />
      )}
    </>
  )
}

export default Header
