import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import appReducer from "./reducers/appReducer";

const rootReducer = combineReducers({
  appStore: appReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunkMiddleware];

if (process.env.NODE_ENV === `development`) {
  const {createLogger} = require(`redux-logger`);
  const logger = createLogger({
    diff: true,
    collapsed: true,
    titleFormatter: action => `[action] ${action.type}`,
    level: {prevState: false, nextState: false, error: false},
  });
  middleware.push(logger);
}

const enhancers = composeEnhancers(applyMiddleware(...middleware));

const store = createStore(rootReducer, {}, enhancers);

export default store;