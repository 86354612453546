import React, {useEffect} from 'react';
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import PageHelmet from "../../components/PageHelmet";
import config from "../../config";
import {MyLink} from "../../components/MyLink";
import {LINKS} from "../../helpers/constants";
import {getNameInitials} from "../../helpers/utils";
import Avatar from "../../components/UI/Avatar";
import InvestProgressBar from "../../components/InvestProgressBar";
import MiniFaqs, {MINIFAQ_SOURCES} from "../../containers/MiniFaqs";
import {getFundraisingDates, getFundraisingStats, getParticipatingFounders} from "../../store/actions/appAction";
import Countdown from "../../components/Countdown";
import Linker from "../../components/Linker";
import SimpleStepper from "../../components/SimpleStepper";
import './index.scss';

const SEO = {
  title: 'Investment Round│Scramble',
  description: "Here's your opportunity to invest in high-growth startups on Scramble. Be part of those amazing success stories during this round's batch. Start investing now!",
  image: null
}

const CONS = [
  {
    title: 'Above-average\n annual returns',
    desc: 'from 12% to 18% per\n annum in euro',
    link: 'https://scrambleup.com/product?step=3'
  },
  {
    title: 'High degree of\n protection',
    desc: 'Three-stage risk\n protection mechanics',
    link: 'https://scrambleup.com/product?step=4'
  },
  {
    title: 'Carefully-vetted\n companies',
    desc: 'A new group of\n companies every month',
    link: 'https://scrambleup.com/how-it-works?step=2'
  }
];

const RoundPage = () => {
  const dispatch = useDispatch();
  const { innerWidth, fundraisingDates, fundraisingStats, participatingFounders } = useSelector(state => state.appStore);
  const { loading, data: fundraising } = fundraisingDates;
  const { data: founders } = participatingFounders;

  useEffect(() => {
    dispatch(getFundraisingDates());
    dispatch(getFundraisingStats());
    dispatch(getParticipatingFounders());
  }, []);

  return (
    <>
      <PageHelmet
        title={SEO.title}
        description={SEO.description}
        image={SEO.image}
        url={config.baseURL + `/round`}
      />

      <div className="round_page">
        <div className="round_page__inner">
          {(loading && !fundraising) ? <div /> : (fundraising && (
            <>
              <div className="round_page__main">
                <div className="container">
                  <h1 className="round_page__title f-42-48 f-600">
                    {fundraising.is_active
                      ? <span>Join the <span className="nowrap">{moment(fundraising.start_date).format('MMMM')}</span> investment round!</span>
                      : <span>The next investment round starts on <span className="nowrap">{moment(fundraising.start_date).format('D MMMM')}</span></span>
                    }
                  </h1>
                  <p className="round_page__desc f-18 f-400">
                    {fundraising.is_active
                      ? "Sign up to Scramble and take part in the funding round! Investing in secured and high-yield loans to innovative businesses has never been easier."
                      : "Be the first to know the founders batch of the next round. Sign up so you don't miss any important information."}
                  </p>

                  {fundraising.is_active && (
                    <Countdown endDate={fundraising.end_date} onFinish={() => dispatch(getFundraisingDates())} className="round_page__countdown f-18 f-500" />
                  )}

                  <MyLink href={LINKS.signUp} isExternal className="round_page__investor_link scr-button yellow one f-18 f-500">
                    {fundraising.is_active ? "Join the investment round" : "Sign up now"}
                  </MyLink>

                  {fundraising.is_active && (
                    <section className="round_page__founders">
                      <h2 className="round_page__contacts-title f-26-32 f-500">
                        Founders batch
                      </h2>

                      <div className="round_page__founders_list">
                        <div className="round_page__founders_list_inner">
                          {founders?.list.map((founder) => (
                            <div className="round_page__founders_item" key={founder.id}>
                              <div className="f-25-30 f-500 tl">{founder.company.name}</div>
                              <ul className="f-18 f-400">
                                {founder.company.sectors.map((sector) => (
                                  <li key={sector.id}>{sector.name}</li>
                                ))}
                                {founder.company.country && <li>{founder.company.country} based</li>}
                                {founder.company.foundation_date && (
                                  <li>Started in&nbsp;{moment(founder.company.foundation_date).format('YYYY')}</li>
                                )}
                              </ul>

                              {founder.company.site && (
                                <Linker
                                  value={founder.company.site}
                                  className="round_page__founders_item_site f-16 f-400 tl"
                                />
                              )}

                              <div className="round_page__founders_item_avatars">
                                {founder.co_founders.map(founder => (
                                  <Avatar
                                    key={founder.id}
                                    size={50}
                                    alt={getNameInitials(founder.first_name, founder.last_name)}
                                    src={founder.photo?.file}
                                    nameInitials={getNameInitials(founder.first_name, founder.last_name)}
                                    className="round_page__founders_item_avatar"
                                  />
                                ))}
                              </div>
                            </div>
                          ))}

                          <div className="round_page__founders_item signup">
                            <div className="round_page__founders_item_centered f-18">
                              <div>Sign up to meet other <br/>amazing founders of the round</div>
                              <MyLink href={LINKS.signUp} isExternal>Create account</MyLink>
                            </div>
                          </div>
                        </div>
                      </div>

                      <InvestProgressBar stats={fundraisingStats} />

                      <MyLink href={LINKS.signUp} isExternal className="round_page__investor_link scr-button yellow two f-16">
                        Join the investment round
                      </MyLink>
                    </section>
                  )}

                  <section className="round_page__how">
                    <h2 className="round_page__how_title f-26-32 f-500">
                      How it works
                    </h2>

                    <div className="round_page__how_inner">
                      <SimpleStepper
                        stepNumber={3}
                        vertical={innerWidth < 940}
                        className="round_page__how_stepper"
                      />
                      <div className="round_page__how_options f-18 f-400">
                        <div className="round_page__how_option">
                          <div>Create your investor account</div>
                          <div>
                            Sign up today and verify<br /> your identity
                          </div>
                        </div>
                        <div className="round_page__how_option">
                          <div>Invest into innovative businesses</div>
                          <div>Take part in the investment<br /> round. You can invest as little as you want</div>
                        </div>
                        <div className="round_page__how_option">
                          <div>Receive monthly repayments</div>
                          <div>Receive your repayments every month</div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="round_page__why">
                    <h2 className="round_page__why_title f-26-32 f-500">
                      Why Scramble?
                    </h2>
                    <div className="round_page__why_circles">
                      <div className="round_page__why_circles_inner">
                        {CONS.map(item => (
                          <MyLink key={item.title} href={item.link} isExternal className="round_page__why_circle">
                            <span className="round_page__why_circle_content">
                              <span className="f-18 f-500">{item.title}</span>
                              <span className="f-16">{item.desc}</span>
                            </span>
                          </MyLink>
                        ))}
                      </div>
                    </div>
                  </section>
                </div>
              </div>

              <section className="round_page__faq">
                <div className="container">
                  <MiniFaqs source={MINIFAQ_SOURCES.round} />

                  <MyLink href={LINKS.signUp} isExternal className="round_page__investor_link scr-button yellow three f-16">
                    {fundraising.is_active ? "Join the investment round" : "Sign up now"}
                  </MyLink>
                </div>
              </section>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default RoundPage;