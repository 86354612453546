import {Link} from 'react-router-dom';
import parse, { domToReact } from 'html-react-parser';

const BASE_URL = window.location.origin.toString();
const CURRENCY = '€';

export const getNameInitials = (firstname = '', lastname = '') =>
  [firstname, lastname]
    .map(name => name.charAt(0))
    .filter(initial => initial)
    .join('')
    .toUpperCase()
    .trim();

export const parseWithLinks = text => {
  if (!text) {
    return null;
  }

  const DOMAIN_URL_REGEX = new RegExp('^' + BASE_URL, 'g');
  const options = {
    replace: ({ name, attribs, children }) => {
      if (name === 'a' && attribs.href && DOMAIN_URL_REGEX.test(attribs.href)) {
        return (
          <Link to={attribs.href.replace(BASE_URL, '')}>
            {domToReact(children)}
          </Link>
        );
      }
    },
  };

  return parse(text, options);
};

export const round = (num, decimalPlaces) => {
  try {
    num = Math.round(`${num}e${decimalPlaces}`);
    return Number(`${num}e${-decimalPlaces}`) || 0;
  } catch (e) {
    return 0;
  }
};

export const prettyFloatMoney = (value, disableCurrency) => {
  try {
    if (!value || !Number(value)) {
      return !disableCurrency ? `${CURRENCY}${String.fromCharCode(160)}0` : 0;
    }
    const x = round(value, 2);
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    return `${
      !disableCurrency ? `${CURRENCY}${String.fromCharCode(160)}` : ''
    }${parts.join('.')}`;
  } catch (e) {
    return !disableCurrency ? `${CURRENCY}${String.fromCharCode(160)}0` : 0;
  }
};

export const secondsToDhms = seconds => {
  seconds = Number(seconds);
  const w = Math.floor(seconds / (3600 * 24 * 7));
  const d = Math.floor((seconds % (3600 * 24 * 7)) / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const wDisplay = w > 0 ? w + (w === 1 ? ' week' : ' weeks') : '';
  const dDisplay = d > 0 ? d + (d === 1 ? ' day' : ' days') : '';
  const hDisplay = h > 0 ? h + (h === 1 ? ' hour' : ' hours') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' minute' : ' minutes') : '';
  const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : '';

  let fullTime;

  if (wDisplay) {
    fullTime = [wDisplay, dDisplay]
  } else if (!wDisplay && !dDisplay && !hDisplay) {
    fullTime = [mDisplay, sDisplay]
  } else if (!wDisplay && !dDisplay) {
    fullTime = [hDisplay, mDisplay]
  } else if (!wDisplay) {
    fullTime = [dDisplay, hDisplay]
  }
  else {
    fullTime = [wDisplay, dDisplay, hDisplay, mDisplay, sDisplay].slice(0, 2);
  }

  return fullTime.filter(item => item)
    .join(' ')
    .trim();
};