import React, {useState} from 'react';
import axios from "axios";
import * as Yup from 'yup';
import {InputTextField} from "../UI/InputTextField";
import {Formik} from 'formik';
import './index.scss';

const VALIDATION = Yup.object({
  firstName: Yup.string()
    .max(40, 'Максимальное кол-во символов 40')
    .required('Имя обязательное поле'),
  lastName: Yup.string()
    .max(40, 'Максимальное кол-во символов 40')
    .required('Фамилия обязательное поле'),
  message: Yup.string()
    .max(140, 'Не меньше 140 символов')
    .required('Сообщение обязательное поле'),
  email: Yup.string()
    .email('Невалидный email')
    .required('Почта обязательное поле')
});

const ContactForm = ({source}) => {
  const [sent, setSent] = useState(false);
  const [state, setState] = useState({
    loading: false,
    errors: false,
  });

  const onSubmit = (values, {setErrors, resetForm}) => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      message: values.message,
      source: window.location.href
    }

    axios.post('https://founder.scrambleup.com/api/promo_email/', payload)
      .then(response => {
          if (response && response.status === 200) {
            source && window.gtag && window.gtag('event', 'send_form', {
              'event_category' : 'promo',
              'event_label' : source
            });
            setSent(true);
            resetForm();
            return setState({
              loading: false,
              errors: null
            });
          }
          throw Error();
        },
        error => {
         if (error?.response?.status === 406) {
           setErrors(error.response.data.errors);
           return setState({
             loading: false,
             errors: error.response.data.errors
           })
          }

          setState({
            loading: false,
            errors: {}
          });
        }).catch(() => {
          setState({
            loading: false,
            errors: {}
          })
    });
  }

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={VALIDATION}
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        message: '',
      }}
    >
      {({values, errors, touched, handleChange, handleSubmit}) => (
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="contact-form__left">
            <div className="contact-form__desc f-25-30 f-400">
              Если у тебя есть вопросы, мы с радостью на них ответим! Оставь сообщение и адрес email, и наш менеджер напишет тебе.
            </div>

            <div className="contact-form__form">
              <div className="contact-form__row">
                <InputTextField
                  label="Имя"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  error={touched.firstName && errors.firstName}
                />
                <InputTextField
                  label="Фамилия"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  error={touched.lastName && errors.lastName}
                />
              </div>

              <InputTextField
                label="Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                error={touched.email && errors.email}
              />

              <InputTextField
                label="Сообщение"
                name="message"
                value={values.message}
                onChange={handleChange}
                error={touched.message && errors.message}
              />
            </div>
          </div>

          <div className="contact-form__right">
            <button
              type="submit"
              disabled={sent}
              className="contact-form__submit"
              onSubmit={handleSubmit}
            >
              {sent ? 'Отправлено' : 'Отправить'}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ContactForm;